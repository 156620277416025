const DEFAULT_SAMPLE_RATIO = 10
const store = {}

const getStore = () => store
const shouldAlwaysInclude = (sampleRatioState, isDebug) => sampleRatioState === 'none' || isDebug && sampleRatioState !== 'force'
const fromCoinFn = (sampleRatio = DEFAULT_SAMPLE_RATIO, coin) => coin % sampleRatio === 0
const getFromCoin = sampleRatio => fromCoinFn.bind(null, sampleRatio)

function isSiteInSampleRatio({sampleRatio, sampleRatioState, viewerSessionId, requestId, isDebug, biSampleByRequestId}) {
  const fromCoin = getFromCoin(sampleRatio)
  if (shouldAlwaysInclude(sampleRatioState, isDebug)) {
    return true
  }

  if (!('coinFromRequestId' in store) && requestId) {
    store.coinFromRequestId = parseInt(requestId, 10)
  }

  const isServerSide = !viewerSessionId
  if (isServerSide) {
    return fromCoin(store.coinFromRequestId)
  }

  if (!('coin' in store)) {
    store.coin = parseInt(viewerSessionId, 16)
  }

  let res = fromCoin(store.coin)
  if (!res && biSampleByRequestId && 'coinFromRequestId' in store) {
    res = fromCoin(store.coinFromRequestId)
  }

  return res
}

export {
  isSiteInSampleRatio,
  getStore
}
